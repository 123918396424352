let FINAL_URLS;
if (process.env.REACT_APP_ENV === 'qa') {
  FINAL_URLS = {
    signup: `https://license.qa.mymiracl.com/stripe/checkout`,
    signin: `https://dashboard.qa.mymiracl.com/auth/realms/api/qv_realm/isTenantExists`,
    emailCheck: `https://disposable.debounce.io/?email=`,
    docs: `https://docs.mymiracl.com`,
    publishableKey: "pk_test_51LPgS4SJlCAwCOaU4m0fzco2O0ppcrjU0PfHA85Smehi0jIHluEs43ctCSpcKhaTccpEi0GBfDJi2fBJKsjFisqo00enbI5xcO"
  };
} else if (process.env.REACT_APP_ENV === 'prod') {
  FINAL_URLS = {
    signup: `https://license.mymiracl.com/stripe/checkout`,
    signin: `https://dashboard.mymiracl.com/auth/realms/api/qv_realm/isTenantExists`,
    emailCheck: `https://disposable.debounce.io/?email=`,
    docs: `https://docs.mymiracl.com`,
    publishableKey: "pk_test_51LPgS4SJlCAwCOaU4m0fzco2O0ppcrjU0PfHA85Smehi0jIHluEs43ctCSpcKhaTccpEi0GBfDJi2fBJKsjFisqo00enbI5xcO"
  };
} else {
  FINAL_URLS = {
    signup: `https://license.dev.mymiracl.com/stripe/checkout`,
    signin: `https://dashboard.dev.mymiracl.com/auth/realms/api/qv_realm/isTenantExists`,
    emailCheck: `https://disposable.debounce.io/?email=`,
    docs: `https://dev-docs.mymiracl.com`,
    publishableKey: "pk_test_51LPgS4SJlCAwCOaU4m0fzco2O0ppcrjU0PfHA85Smehi0jIHluEs43ctCSpcKhaTccpEi0GBfDJi2fBJKsjFisqo00enbI5xcO"
  };
}

export const FETCH_URLS = FINAL_URLS;

