import React from 'react'

const Footer = () => {
  return (
    <footer className="h-[50px] leading-[50px] text-center select-none">
        <div className="inline-flex">
          <a
            href="https://miracl.com/miracl-trust-multi-factor-authentication/"
            target="_blank"
            rel="noreferrer"
            className="relative z-100 inline-flex items-center"
          >
            Secured by{" "}
            <img
              src="assets/images/logos/miracl.svg"
              className="w-[117px] h-[25px] inline-block"
              alt="miracl logo"
            />
          </a>
        </div>
      </footer>
  )
}

export default Footer