import { useState } from "react";
import { FETCH_URLS } from "../config";
import { Link } from "react-router-dom";
import { Footer , FormField} from "../components";
import { Validations } from "../services";

const SignIn = () => {
  const { signin: signinAPI } = FETCH_URLS;

  const formGroupBaseClass =
    "relative z-0 inline-block mb-6 text-[18px] group text-left w-[386px] max-w-full form-group";

  const [workspace, setWorkspace] = useState("");
  const [workspaceErr, setWorkspaceErr] = useState(null);

  const [unsuccessfull, setUnsuccessfull] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errMessage, setErrMessage] = useState("");
  const [workspaceInputScrollWidth, setWorkspaceInputScrollWidth] =
    useState("");

  const trimInputs = () => {
    setWorkspace(workspace.trim());
  };

  const validateWorkspace = (workspace) => {
    let valid = true;
    for (
      let itr = 0;
      itr < Validations.companyNameValidationRules.length;
      itr++
    ) {
      let result = Validations.companyNameValidationRules[itr](workspace);
      if (result !== true) {
        setWorkspaceErr(result);
        valid = false;
        break;
      }
    }
    return valid;
  };

  const setError = (message) => {
    setErrMessage(message ? message : "Something went wrong!");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    trimInputs();
    // Resetting the states
    setUnsuccessfull(false);
    setErrMessage("");
    // To show loader
    setLoading(true);
    let raw = JSON.stringify({
      "tenantName": workspace
    });
    let requestOptions = {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json"
      },
      "body": raw
    };

    fetch(signinAPI, requestOptions)
      .then((res) => {
        switch (res.status) {
          case 200:
            return res
              .json()
              .then((data) => {
                window.location.href = data.data;
              })
              .catch((err) => {
                setUnsuccessfull(true);
                setError();
                setLoading(false);
              });
          case 500:
            return res
              .json()
              .then((data) => {
                setUnsuccessfull(true);
                setError(data.message);
                setLoading(false);
              })
              .catch((err) => {
                setUnsuccessfull(true);
                setError();
                setLoading(false);
              });
          case 404:
            return res
              .json()
              .then((data) => {
                setUnsuccessfull(true);
                setLoading(false);
              })
              .catch((err) => {
                setUnsuccessfull(true);
                setError();
                setLoading(false);
              });
          default:
            setError();
            setLoading(false);
        }
      })
      .catch((err) => {
        setUnsuccessfull(true);
        setError();
        setLoading(false);
      });
  };

  return (
    <>
      <div className="relative min-h-full -mb-[50px] pb-[50px] select-none">
        <div>
          <section className="w-full">
            <div className="w-full xs:min-w-[440px] m-auto p-[27px] pb-0 xs:pb-[27px] text-center">
              <div className="inline">
                <img
                  className="max-w-[386px] max-h-[40px] mx-auto xs:max-h-[60px]"
                  src="assets/images/logos/miracl.svg"
                  alt="MIRACL IAM Portal"
                />
              </div>
              <div className="mx-[10px] mt-[10px]">
                <div
                  className="text-[24px] font-thin overflow-ellipsis leading-[30px] whitespace-nowrap overflow-hidden select-text inline-block"
                  title="MIRACL IAM Portal"
                >
                  MIRACL IAM Portal
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="relative pb-[30px]">
          <div className="w-full">
            <form
              className="min-h-auto rounded-[10px] xs:border border-solid border-[#e6e9eb] w-full max-w-[440px] mx-auto p-[27px] bg-white z-[1000] text-center relative"
              onSubmit={onSubmit}
            >
              <div>
                <div>
                  <h3 className="inline-block h-[35px] mt-0 mb-[16px] font-thin leading-[35px] uppercase text-[15.21px] select-text text-[#455560]">
                    Sign In to Continue
                  </h3>
                  {/* Workspace Name Field */}
                  <FormField
                    value={workspace}
                    type={"text"}
                    name={"floating_name"}
                    onChange={(e, groupRef) => {
                      let result = validateWorkspace(e.target.value);
                      if (
                        !result &&
                        !groupRef.current.className.includes("error")
                      ) {
                        groupRef.current.className = `${groupRef.current.className} error`;
                      } else if (
                        result &&
                        groupRef.current.className.includes("error")
                      ) {
                        groupRef.current.className =
                          groupRef.current.className.replace("error", "");
                      }
                      if (result) {
                        setWorkspaceErr("");
                      }
                      setErrMessage("");
                      setUnsuccessfull(false);
                      setWorkspace(e.target.value);
                    }}
                    label={"Workspace Name"}
                    error={workspaceErr}
                    formGroupBaseClass={formGroupBaseClass}
                  />
                  {unsuccessfull ? (
                    <>
                      {errMessage ? (
                        <p className="text-xs leading-5 text-[#be360c] whitespace-normal">
                          {errMessage}
                        </p>
                      ) : (
                        <p className="text-xs leading-5 text-[#be360c] whitespace-normal">
                          The workspace name you have entered can’t be found on
                          our records. Please try{" "}
                          <Link to="/sign-up" className="font-bold">
                            sign up
                          </Link>
                          .
                        </p>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {loading ? (
                    <button
                      disabled
                      type="button"
                      className="text-center pointer-events-none cursor-auto bg-[#005581] border border-[#005581] w-full text-white rounded-lg transition-all duration-200 ease-in-out mt-5 font-medium uppercase leading-[34px] select-none h-[36px] flex items-center justify-center"
                    >
                      <svg
                        role="status"
                        className="inline w-4 h-4 mr-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="bg-[#005581] border border-[#005581] w-full text-white rounded-lg transition-all duration-200 ease-in-out mt-5 font-semibold uppercase leading-[34px] select-none h-[36px] flex items-center justify-center disabled:opacity-30 disabled:pointer-events-none hover:shadow-buttonShadow cursor-pointer disabled:cursor-auto"
                      disabled={!workspace || workspaceErr}
                      type="submit"
                    >
                      Sign in
                    </button>
                  )}

                  <div className="mt-6 mb-0">
                    <p className="text-center mb-0 text-base text-[#1C282E]">
                      <span className="select-text">
                        Don't have an account?
                      </span>{" "}
                      <Link to="/sign-up" className="text-base text-[#005581]">
                        {" "}
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;
